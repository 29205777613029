<template>
  <div class="content d-flex flex-column flex-column-fluid">
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body p-0">
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <b-form @submit.prevent="updateCompany">
                  <h3 class="mb-10 font-weight-bold text-dark">
                    Company Details:
                  </h3>
                  <div class="row">
                    <div class="col-xl-12">
                      <b-form-group label-cols-sm="3" id="input-group-1" label="Company Name" label-for="input-1">
                        <b-form-input
                          v-model="name"
                          id="input-1"
                          type="text"
                          name="company_name"
                          class="form-control form-control-lg form-control-solid"
                        >
                        </b-form-input>
                      </b-form-group>

                      <b-form-group label-cols-sm="3" id="input-group-1" label="Company Email" label-for="input-1">
                        <b-input-group size="lg">
                          <b-input-group-prepend>
                            <span class="input-group-text">
                              <i class="la la-at"></i>
                            </span>
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="email"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="company_email"
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>

                      <b-form-group label-cols-sm="3" id="input-group-1" label="Company Phone" label-for="input-1">
                        <b-input-group size="lg">
                          <b-input-group-prepend>
                            <span class="input-group-text">
                              <i class="la la-phone"></i>
                            </span>
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="phone"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="company_phone"
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>

                      <b-form-group label-cols-sm="3" id="input-group-1" label="Location" label-for="input-1">
                        <b-form-input
                          v-model="location"
                          id="input-1"
                          type="text"
                          name="location"
                          class="form-control form-control-lg form-control-solid"
                        >
                        </b-form-input>
                      </b-form-group>

                      <b-form-group label-cols-sm="3" id="input-group-1" label="Image URL" label-for="input-1">
                        <b-form-input
                          v-model="image_path"
                          id="input-1"
                          type="text"
                          name="image_url"
                          class="form-control form-control-lg form-control-solid"
                        >
                        </b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between border-top pt-10">
                    <button type="submit" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">
                      Submit
                    </button>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClientCompany, updateClientCompany } from "@/api/client.api";

import { ref, onMounted } from "@vue/composition-api";
import { useNotify } from "@/utils/notify";
export default {
  name: "updateClientCompany",

  setup(_, context) {
    const { notify } = useNotify(context);
    onMounted(async () => {
      try {
        const response = await getClientCompany(context.root.$route.params.id);
        const data = response.data;

        id = data.id;
        name.value = data.name;
        email.value = data.email;
        phone.value = data.phone;
        location.value = data.location;
        image_path.value = data.image_path;
      } catch (e) {
        context.root.$router.push({ name: "ListClientCompany" });
      }
    });

    let id = 0;
    const name = ref("");
    const email = ref("");
    const phone = ref("");
    const location = ref("");
    const image_path = ref("");

    const updateCompany = async function() {
      const payload = {
        name: name.value,
        email: email.value,
        phone: phone.value,
        location: location.value,
        image_path: image_path.value
      };

      try {
        await updateClientCompany(id, payload);
        notify("Company updated", `The client company is updated`);
        context.root.$router.push({ name: "ListClientCompany" });
      } catch (e) {
        console.error(e);
      }
    };

    return {
      name,
      email,
      phone,
      location,
      image_path,

      updateCompany
    };
  }
};
</script>
