import ApiService from "@/core/services/api.service";

const COMPANY_END_POINT = "/client-company";

const getAllClientCompany = () => ApiService.get(`${COMPANY_END_POINT}`);

const getClientCompany = id => ApiService.get(`${COMPANY_END_POINT}`, id);

const createClientCompany = param => ApiService.post(`${COMPANY_END_POINT}`, param);

const updateClientCompany = (id, param) => ApiService.update(`${COMPANY_END_POINT}`, id, param);

const lockClientCompany = id => ApiService.put(`${COMPANY_END_POINT}/${id}/lock`);
const unlockClientCompany = id => ApiService.put(`${COMPANY_END_POINT}/${id}/unlock`);

const getUsersByCompany = id => ApiService.get(`${COMPANY_END_POINT}/${id}/users`);

const createClientUser = (id, param) => ApiService.post(`${COMPANY_END_POINT}/${id}/users`, param);

const updateClientUser = (id, company, param) => ApiService.put(`${COMPANY_END_POINT}/${company}/users/${id}`, param);

const lockClientUser = (id, company) => ApiService.put(`${COMPANY_END_POINT}/${company}/users/${id}/lock`);
const unlockClientUser = (id, company) => ApiService.put(`${COMPANY_END_POINT}/${company}/users/${id}/unlock`);

const resetClientUserPassword = (id, company) => ApiService.put(`${COMPANY_END_POINT}/${company}/users/${id}/reset`);

export {
  getAllClientCompany,
  getClientCompany,
  createClientCompany,
  updateClientCompany,
  lockClientCompany,
  unlockClientCompany,
  getUsersByCompany,
  createClientUser,
  updateClientUser,
  lockClientUser,
  unlockClientUser,
  resetClientUserPassword
};
