export function useNotify({ root }) {
  const notify = (title, msg, variant = "success", toaster = "b-toaster-bottom-right") => {
    root.$bvToast.toast(msg, {
      title,
      variant: variant,
      toaster: toaster
    });
  };

  return { notify };
}

export default useNotify;
